<template>
  <Signa1ExpandableList
    :label="$t('pages.physicalObjects.choosePhysicalObject')"
  >
    <template v-slot:signum1="{ element: { current, signum1 } }">
      <PhysicalObjectList v-if="current" :signum1="signum1" />
    </template>
    <template v-slot:list-extras>
      <b-list-group flush>
        <b-list-group-item>
          <a href="" @click.prevent="showUnknown = !showUnknown">
            {{ $t('pages.physicalObjectTree.showUnknown')}}
          </a>
        </b-list-group-item>
        <b-list-group-item v-if="showUnknown">
          <physical-object-list-unknown />
        </b-list-group-item>
      </b-list-group>
    </template>
  </Signa1ExpandableList>
</template>

<script>
export default {
  name: "PhysicalObjectTree",
  components: {
    Signa1ExpandableList: () => import("@/components/Signa1ExpandableList"),
    PhysicalObjectList: () => import("./PhysicalObjectList"),
    PhysicalObjectListUnknown: () => import("./PhysicalObjectListUnknown")
  },
  props: {
    preselectedSignum1: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showUnknown: false
    }
  }
};
</script>
